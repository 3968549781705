import React from 'react';
import css from './Button.module.css';

export default function Button({ children, ...rest }) {
  return (
    <button type="button" className={css.main} {...rest}>
      {children}
    </button>
  );
}
