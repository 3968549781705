import React, { useEffect, useMemo } from 'react';

import { uniqueId } from 'lodash';
import css from './Activity.module.css';
import useSearchCounts from '../../../../../../pages/Search/hooks/useSearchCounts';
import Regulation from '../../../../../../icons/Regulation';
import Event from '../../../../../../icons/Event';
import Quote from '../../../../../../icons/Quote';
import useNavigation from '../../../../../hooks/useNavigation';
import { formatTotal } from '../../../../../../components/Total/Total';
import { ROUTES } from '../../../../../shared/routes';
import { IdHash } from '../../../../../shared/dossier';

const buildOptions = (counts) => {
  const options = [];

  options.push({
    title: 'Regulations',
    type: 'regulation',
    icon: <Regulation />,
    count: counts?.regulations || 0,
  });

  options.push({
    title: 'News',
    type: 'event',
    subType: 'news',
    icon: <Event />,
    count: counts?.news || 0,
  });

  options.push({
    title: 'Quotes',
    type: 'event',
    subType: 'quote',
    icon: <Quote />,
    count: counts?.quote || 0,
  });

  return options;
};

const Activity = ({ entity }) => {
  const { navigate, params } = useNavigation();
  const { counts, fetchCounts, isCountLoading } =
    useSearchCounts('cache-first');

  useEffect(() => {
    fetchCounts({
      search: '',
      industries: [],
      countries: [],
      entities: [entity.id],
      dates: [],
      filterActorTypes: [],
    });
  }, [entity.id]);

  const onActivityClick = (item) => {
    const uuid = uniqueId('search-');
    params.set('queryId', uuid);
    // params.set('isFilterOpen', true);
    params.delete('search');
    sessionStorage.setItem(
      uuid,
      JSON.stringify({
        industries: [],
        countries: [],
        dates: [],
        entities: [entity],
        searchTypes: item.type,
        eventTypes: [item.subType],
      }),
    );

    navigate(ROUTES.ADV_SEARCH, params, {}, IdHash.search);
  };

  const activityOptions = useMemo(() => buildOptions(counts), [counts]);

  if (isCountLoading) {
    return <Loading />;
  }

  return (
    <div className={css.activity}>
      {activityOptions.map((item) => (
        <div
          key={`${item.type}-${item.subType}`}
          data-disabled={item.count === 0}
          className={css.activityItem}
          onClick={() => onActivityClick(item)}
        >
          <div className={css.activityIcon}>{item.icon}</div>

          <div className={css.activityContent}>
            <div className={css.activityTitle}>
              {formatTotal(item.count)} {item.title}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Activity;

function Loading() {
  return (
    <div className={css.activity} style={{ height: '180px' }}>
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
      <div className="skeleton-v2" />
    </div>
  );
}
