import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';

import useDatesUrlState from '../../../hooks/useDatesUrlState';
import useUrlState from '../../../hooks/useUrlState';

import Appointments from './Appointments/Appointments';
import Header from './Header/Header';
import News from './News/News';
import Quotes from './Quotes/Quotes';
import ScatterGraph from './ScatterGraph/ScatterGraph';
import css from './ScatterPlotEvents.module.css';

import GET_FILTERED_EVENTS from '../../../queries/getFilteredEventsForTreeMap';
import GET_COUNTS from '../../../queries/searchCounts';

function ScatterPlotEvents() {
  const [industryId, setIndustryId] = useUrlState('industryId');
  const [countryId, setCountryId] = useUrlState('countryId');
  const [[startDate, endDate], setDates] = useDatesUrlState(); // miliseconds
  const [selectedNode, setSelectedNode] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(null);

  const { loading } = useQuery(GET_FILTERED_EVENTS, {
    fetchPolicy: 'cache-first',
    variables: { eventIds: selectedNode?.eventIds },
    skip: !selectedNode,
    onCompleted: (data) => {
      setFilteredEvents(data.getEventById.events);
    },
  });

  const { data } = useQuery(GET_COUNTS, {
    variables: {
      filterIndustries: [industryId],
      topics: [countryId],
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  });

  const cleanNodeData = () => {
    setSelectedNode(null);
    setFilteredEvents(null);
  };

  const setDateInterval = (dates) => {
    setDates({
      startDate: dates[0]?.getTime(),
      endDate: dates[1]?.getTime(),
    });
  };

  const counts = data?.searchEventsAndRegulationsCount.eventTypesCount;

  const [quotesCount, appointmentsCount] = useMemo(() => {
    if (!counts) return ['', ''];

    return [
      counts.find(({ type }) => type === 'quote')?.total || '',
      counts.find(({ type }) => type === 'appointment')?.total || '',
    ];
  }, [counts]);

  useEffect(() => {
    cleanNodeData();
  }, [industryId, countryId, startDate, endDate]);

  return (
    <div className={css.main}>
      <Header
        industryId={industryId}
        setIndustryId={setIndustryId}
        endDate={endDate}
        startDate={startDate}
        countryId={countryId}
        setCountryId={setCountryId}
        setDateInterval={setDateInterval}
      />
      <ScatterGraph
        countryId={countryId}
        industryId={industryId}
        endDate={endDate}
        startDate={startDate}
        setSelectedNode={setSelectedNode}
        onClickOutside={cleanNodeData}
        selectedNodeId={selectedNode?.id}
      />
      <Quotes
        className={css.quotes}
        countryId={countryId}
        industryId={industryId}
        count={quotesCount}
        startDate={startDate}
        endDate={endDate}
      />
      <Appointments
        className={css.appointments}
        countryId={countryId}
        industryId={industryId}
        count={appointmentsCount}
        startDate={startDate}
        endDate={endDate}
      />
      <div className={css.news}>
        <News
          countryId={countryId}
          industryId={industryId}
          counts={counts}
          startDate={startDate}
          endDate={endDate}
          filteredEvents={filteredEvents}
          isLoadingFilteredEvents={loading}
        />
      </div>
    </div>
  );
}

export default ScatterPlotEvents;
