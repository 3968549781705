import React from 'react';

import css from './SingleSelectionButton.module.css';
import ChevronDown from '../../../../../icons/ChevronDown';

const SingleSelectionButton = React.forwardRef(
  ({ name, onClick, ...rest }, ref) => {
    return (
      <div className={css.main} onClick={onClick} ref={ref} {...rest}>
        <span className={css.name}>{name}</span>
        <ChevronDown />
      </div>
    );
  },
);

export default SingleSelectionButton;
