import React, { useEffect, useMemo, useReducer, useRef } from 'react';

import css from './IndustryFilter.module.css';
import FilterButton from '../components/FilterButton/FilterButton';
import FilterPopUp from '../components/FilterPopUp/FilterPopUp';
import { PopUpCard } from '../components/PopUpCard/PopUpCard';
import { reducer, industryFilterState, ACTIONS } from './reducer';
import MultiSelectionTree from '../../../../components/MultiSelectionTree/MultiSelectionTree';
import { filterInTree } from '../../../../utils';
import ListDivider from '../components/ListDivider/ListDivider';
import SingleSelectionButton from '../components/SingleSelectionButton/SingleSelectionButton';

function IndustryFilter({
  activeIndustryFilters,
  options,
  applyFilters,
  singleSelection = false,
  openAtBottom = false,
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...industryFilterState,
    options,
    singleSelection,
  });

  const buttonRef = useRef();

  /** Create a flat array from original options tree, it's a faster way to iterate over the tree */
  const flatenOptions = useMemo(() => {
    if (singleSelection) return options;

    const sectors = options;
    const subSectors = sectors.flatMap((option) => option.children);
    const industries = subSectors.flatMap((option) => option.children);
    return [sectors, subSectors, industries].flat();
  }, [options]);

  const onClose = () => {
    dispatch({ type: ACTIONS.CLOSE_POPUP });
  };

  const handleButtonClick = () => {
    const type = state.isPopupOpen ? ACTIONS.CLOSE_POPUP : ACTIONS.OPEN_POPUP;
    dispatch({
      type,
      payload: {
        selectedOptions: activeIndustryFilters,
        options,
      },
    });
  };

  const onCancel = () => {
    onClose();
  };

  const onSelectAll = () => {
    if (singleSelection) return;

    dispatch({ type: ACTIONS.SELECT_ALL });
  };

  const onDeselectAll = () => {
    dispatch({ type: ACTIONS.DESELECT_ALL });
  };

  const onApply = () => {
    if (singleSelection && state.selectedOptions.length === 0) {
      return;
    }

    dispatch({ type: ACTIONS.CLOSE_POPUP });
    applyFilters(
      flatenOptions.filter((option) =>
        state.selectedOptions.includes(option.id),
      ),
    );
  };

  const onInputChange = (event) => {
    dispatch({ type: ACTIONS.UPDATE_QUERY, payload: event.target.value });
  };

  const filteredOptions = useMemo(
    () => filterInTree(state.options, state.query),
    [state.options, state.query],
  );

  /** Sync active filters with tree view  */
  useEffect(() => {
    dispatch({
      type: ACTIONS.SYNC_SELECTED_OPTIONS,
      payload: activeIndustryFilters,
    });
  }, [activeIndustryFilters]);

  return (
    <div data-cy="industry-filter" ref={buttonRef}>
      <FilterPopUp
        isPopoverOpen={state.isPopupOpen}
        onClickOutside={onClose}
        openAtBottom={openAtBottom}
        content={
          <PopUpCard.Root>
            <PopUpCard.Header
              title="Industries"
              isPopupOpen={state.isPopupOpen}
              count={singleSelection ? '' : state.selectedOptions.length}
              inputValue={state.query}
              onInputChange={onInputChange}
            />
            <PopUpCard.Body>
              <div className={css.list}>
                {state.selectedOptionsTree.length > 0 && (
                  <>
                    <ListDivider
                      title={
                        singleSelection
                          ? 'SELECTED INDUSTRY'
                          : 'SELECTED INDUSTRIES'
                      }
                      buttonLabel={singleSelection ? '' : 'Deselect All'}
                      onClick={onDeselectAll}
                    />
                    <MultiSelectionTree
                      singleSelection={singleSelection}
                      dispatch={dispatch}
                      options={state.selectedOptionsTree}
                      expandAll={Boolean(state.query)}
                      selectedOptions={state.selectedOptions}
                      expandedOptions={state.expandedOptions}
                    />
                  </>
                )}

                <ListDivider
                  title="ALL INDUSTRIES"
                  buttonLabel={singleSelection ? '' : 'Select All'}
                  onClick={onSelectAll}
                />
                <MultiSelectionTree
                  singleSelection={singleSelection}
                  dispatch={dispatch}
                  options={filteredOptions}
                  expandAll={Boolean(state.query)}
                  selectedOptions={state.selectedOptions}
                  expandedOptions={state.expandedOptions}
                />
              </div>
            </PopUpCard.Body>
            <PopUpCard.Footer onApply={onApply} onCancel={onCancel} />
          </PopUpCard.Root>
        }
      >
        {singleSelection ? (
          <SingleSelectionButton
            aria-pressed={state.isPopupOpen}
            name={activeIndustryFilters?.[0]?.name ?? 'Select Industry'}
            count={activeIndustryFilters.length}
            onClick={handleButtonClick}
          />
        ) : (
          <FilterButton
            aria-pressed={state.isPopupOpen}
            name="Industries"
            count={activeIndustryFilters.length}
            onClick={handleButtonClick}
          />
        )}
      </FilterPopUp>
    </div>
  );
}

export default IndustryFilter;
