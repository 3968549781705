import useNavigation from './useNavigation';

export default function useFilterSidebar() {
  const { params, replaceParams } = useNavigation();

  const isFilterOpen = params.get('isFilterOpen') === 'true';

  const toggleFilter = () => {
    params.delete('leftPanel');
    params.set('isFilterOpen', !isFilterOpen);
    replaceParams(params);
  };

  return {
    isFilterOpen,
    toggleFilter,
  };
}
