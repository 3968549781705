import React, { useEffect, useState } from 'react';

import css from './BaseCard.module.css';
import { formatDate } from '../../../../lib/date';
import Source from '../../Source/Source';
import BriefPill from '../../../../components/BriefPill/BriefPill';
import ToggleMore from '../../ToggleMore/ToggleMore';
import AiBadge from '../../AiBadge/AiBadge';
import CardSources from './CardSources/CardSources';

function Root({ children, className = '', ...props }) {
  return (
    <div {...props} className={`${css.card} ${className}`}>
      {children}
    </div>
  );
}

function Row({ children, className = '', ...props }) {
  return (
    <div {...props} className={`${css.row} ${className}`}>
      {children}
    </div>
  );
}

function LeftColumn({ children, className = '', ...props }) {
  return (
    <div {...props} className={`${css.leftColumn} ${className}`}>
      {children}
    </div>
  );
}

function RightColumn({ children, className = '', ...props }) {
  return (
    <div {...props} className={`${css.rightColumn} ${className}`}>
      {children}
    </div>
  );
}

function DateRow({ date, className = '', before, ...props }) {
  if (!date) {
    return null;
  }

  const formatedDate = formatDate(date);
  return (
    <div {...props} className={`${css.dateRow} ${className}`}>
      {before}
      {formatedDate}
    </div>
  );
}

function Title({ children, className = '', onClick, ...props }) {
  const handleClick = () => {
    if (!onClick) return;

    onClick();
  };

  return (
    <div
      {...props}
      data-click={!!onClick}
      className={`${css.title} ${className}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

function Summary({ summary, bullets = [] }) {
  const [expanded, setExpanded] = useState(false);
  const [filteredBullets, setFilteredBullets] = useState([]);

  useEffect(() => {
    if (bullets.length > 5) {
      setFilteredBullets(bullets.slice(0, 4));
      return;
    }

    setFilteredBullets(bullets);
  }, [bullets]);

  const onToggle = () => {
    const newState = !expanded;

    setExpanded(newState);

    if (!newState) {
      setFilteredBullets(bullets.slice(0, 4));
      return;
    }

    setFilteredBullets(bullets);
  };

  return (
    <div className={css.regulation}>
      <p className={css.summary}>
        <AiBadge /> {summary}
      </p>
      <ul className={css.bullets}>
        {filteredBullets.map((bullet) => (
          <li key={bullet}>{bullet}</li>
        ))}
      </ul>
      {bullets.length > 5 && (
        <ToggleMore expanded={expanded} onClick={onToggle} />
      )}
    </div>
  );
}

function InfoColumn({ title, children, className = '', ...props }) {
  return (
    <div {...props} className={`${css.infoColumn} ${className}`}>
      <div className={css.infoTitle}>{title}</div>
      <div className={css.infoChildren}>{children}</div>
    </div>
  );
}

function BottomRow({ children, className = '', ...props }) {
  return (
    <div {...props} id="bottomRow" className={`${css.bottomRow} ${className}`}>
      {children}
    </div>
  );
}

function SourceList({ sources, className = '', ...props }) {
  if (!sources) return null;

  return (
    <div {...props} className={`${css.sources} ${className}`}>
      {sources.map((src) => (
        <Source key={src} src={src} />
      ))}
    </div>
  );
}

function BriefPillList({ briefs, className = '', isGeography, ...props }) {
  if (!briefs) return null;
  return (
    <div {...props} className={`${css.pills} ${className}`}>
      {briefs.map((brief, index) => (
        <BriefPill
          key={`${brief.name}-${index}`}
          name={brief.name}
          relevance={
            isGeography ? brief.locationRelevance : brief.industryRelevance
          }
          tooltip={brief.tooltip}
          isGeography={isGeography}
        />
      ))}
    </div>
  );
}

export const BaseCard = {
  Root,
  Row,
  LeftColumn,
  RightColumn,
  DateRow,
  Title,
  InfoColumn,
  BottomRow,
  SourceList,
  BriefPillList,
  Summary,
  CardSources,
};
