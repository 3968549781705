import { React } from 'react';

import AiBadge from '../../../../v2/components/AiBadge/AiBadge';
import AnimatedLogo from '../../../../v2/components/AxisAnimatedLogo/AnimatedLogo';
import { useSearch } from '../../Search';
import { ResultEntity, ResultFacet } from '../ResultCards/ResultCards';
import css from './CategoryResults.module.css';

export const AiSuggestions = () => {
  const { aiEntitySuggestions } = useSearch();

  if (!aiEntitySuggestions || aiEntitySuggestions.length === 0) return null;

  return (
    <div data-cy="search-results-ai" className={css.category}>
      <p className={css.title}>
        Suggestions <AiBadge />
      </p>
      <div className={css.results}>
        {aiEntitySuggestions.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      </div>
    </div>
  );
};

export const EntityCategoryResults = ({
  name = '',
  total = 0,
  entities = [],
  onClick,
}) => {
  if (total === 0) return null;
  return (
    <div data-cy="search-results-category">
      <p className={css.title}>{name}</p>
      <div className={css.results}>
        {entities.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      </div>
      {total > entities.length && (
        <button
          data-cy="search-results-pagination-button"
          type="button"
          className={css.more}
          onClick={onClick}
        >
          View all {total}
        </button>
      )}
    </div>
  );
};

export const FacetCategoryResults = ({
  type = '',
  name = '',
  total = 0,
  facets = [],
  onClick,
}) => {
  if (total === 0) return null;
  return (
    <div data-cy="search-results-category">
      <p className={css.title}>{name}</p>
      <div className={css.facetResults}>
        {facets.map((facet) => (
          <ResultFacet key={facet.id} facet={facet} type={type} />
        ))}
      </div>
      {total > facets.length && (
        <button
          data-cy="search-results-pagination-button"
          type="button"
          className={css.more}
          onClick={onClick}
        >
          View all {total}
        </button>
      )}
    </div>
  );
};

export const ListResults = ({ isLoading, resultType, type, results }) => (
  <div data-cy="search-results-list-content">
    <div
      className={resultType === 'entity' ? css.results : ''}
      data-cy="search-results-list-results"
    >
      {resultType === 'entity' &&
        results.map((entity) => (
          <ResultEntity key={entity.id} entity={entity} />
        ))}
      {resultType === 'facet' &&
        results.map((facet) => (
          <ResultFacet key={facet.id} facet={facet} type={type} />
        ))}
    </div>
    {isLoading && (
      <div className={css.loading}>
        <AnimatedLogo customClass={css.loadingSpinner} />
      </div>
    )}
  </div>
);
