import React from 'react';

import css from './Header.module.css';
import SingleIndustryFilter from '../SingleIndustryFilter/SingleIndustryFilter';
import SingleCountryFilter from '../SingleCountryFilter/SingleCountryFilter';
import DateFilter from '../../../components/Filters/DateFilter/DateFilter';

function Header({
  startDate,
  endDate,
  industryId,
  setIndustryId,
  countryId,
  setCountryId,
  setDateInterval,
}) {
  return (
    <div className={css.main}>
      <SingleIndustryFilter
        industryId={industryId}
        setIndustryID={setIndustryId}
      />

      <SingleCountryFilter countryId={countryId} setCountryId={setCountryId} />

      <DateFilter
        singleSelection
        dateInterval={[new Date(startDate), new Date(endDate)]}
        setDateInterval={setDateInterval}
        openAtBottom
      />
    </div>
  );
}

export default Header;
