import React, { useEffect, useState } from 'react';
import { useApolloClient } from '../../../../hooks/useApolloClient';

import FilterIcon from '../../../../icons/FilterIcon/FilterIcon';
import HomeIcon from '../../../../icons/Home';

import { formatDate } from '../../../../lib/date';
import notify from '../../../../lib/notify';
import {
  getLabelFromType,
  getTotalSearchResults,
  isLoadingResults,
} from '../../../../lib/search';

import createSavedSearch from '../../../../queries/createSaveSearch';
import findSavedSearchByData from '../../../../queries/findSavedSearchByData';
import listSavedSearchs from '../../../../queries/listSavedSearches';
import updateSavedSearch from '../../../../queries/updateSaveSearch';

import SavedSearchModal from '../../../../components/SavedSearchModal/SavedSearchModal';
import Button from '../../../../v2/components/Button/Button';

import useFilterSidebar from '../../../../v2/hooks/useFilterSidebar';
import useNavigation from '../../../../v2/hooks/useNavigation';
import { ROUTES } from '../../../../v2/shared/routes';
import { useSearch } from '../../Search';
import css from './SearchHeader.module.css';

const getResultsText = (results, query) => {
  const prefix = results > 0 ? 'Results' : 'No results';
  return query ? (
    <>
      {`${prefix} for `}
      <ResultHeaderHighlight text={query} />
    </>
  ) : (
    prefix
  );
};

const createSavedSearchMutation = async ({
  client,
  searchName,
  searchData,
}) => {
  return client
    .mutate({
      mutation: createSavedSearch,
      variables: { searchName, searchData },
    })
    .then(({ data }) => data.createSavedSearch);
};

const updateSavedSearchMutation = async ({
  client,
  savedSearchId,
  updatedName,
  updatedData,
}) => {
  return client
    .mutate({
      mutation: updateSavedSearch,
      variables: { savedSearchId, updatedName, updatedData },
    })
    .then(({ data }) => data.updateSavedSearch);
};

const ResultHeaderHighlight = ({ className = '', text, children }) => (
  <span className={`${className} ${css.titleHighlight}`}>
    {text}
    {children}
  </span>
);

const SearchHeaderGroup = ({ results, summaryText, formatResult }) => {
  if (results.length === 1) {
    return <ResultHeaderHighlight text={formatResult(results[0])} />;
  }

  return (
    <ResultHeaderHighlight className={css.titleFilters} text={summaryText}>
      <div className={css.titleFiltersPopover}>
        {results.map((result) => (
          <ResultHeaderHighlight
            key={result.id}
            className={css.titlePopoverHighlight}
            text={formatResult(result)}
          />
        ))}
      </div>
    </ResultHeaderHighlight>
  );
};

const SearchHeader = () => {
  const {
    activeSearchText,
    entityResults,
    facetResults,
    previousState,
    visibleResultType,
    isEntityResultsLoading,
    isFacetResultsLoading,
    setSavedSearches,
    savedSearchId,
    homeFeedSavedSearch,
    setHomeFeedSavedSearch,
    savedSearchName,
    searchTypes,
  } = useSearch();

  const { isFilterOpen, toggleFilter } = useFilterSidebar();

  const [openSaveSearchModal, setOpenSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');

  const [successfulSaveSearch, setSuccessfulSaveSearch] = useState(false);
  const [updateType, setUpdateType] = useState('save');
  const { navigate, params } = useNavigation();

  const searchDates = previousState?.dates ?? [];
  const searchIndustries = previousState?.industries ?? [];
  const searchEntities = previousState?.entities ?? [];
  const searchCountries = previousState?.countries ?? [];
  const searchLandscapes = [];

  const client = useApolloClient();
  useEffect(() => {
    const searchData = {
      filterEntities: searchEntities.map((entity) => entity.id),
      filterIndustries: searchIndustries.map((industry) => industry.id),
      filterLandscapes:
        (searchLandscapes &&
          searchLandscapes.map((landscape) => landscape.id)) ||
        [],
      name: activeSearchText,
      topics: searchCountries.map((country) => country.id),
      filterCategories: searchTypes,
      searchDates,
      isHomeFeed: false,
    };

    Promise.all([
      client.query({
        query: findSavedSearchByData,
        variables: { searchData: JSON.stringify(searchData) },
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: findSavedSearchByData,
        variables: {
          searchData: JSON.stringify({ ...searchData, isHomeFeed: true }),
        },
        fetchPolicy: 'network-only',
      }),
    ]).then(([{ data }, { data: homeFeedData }]) => {
      const foundSavedSearch =
        data.listSavedSearchs.savedSearches.length > 0 ||
        homeFeedData.listSavedSearchs.savedSearches.length > 0;

      setSuccessfulSaveSearch(foundSavedSearch);

      const homeFeedSavedSearchItem =
        homeFeedData.listSavedSearchs.savedSearches.find(
          (d) => JSON.parse(d.search_data).isHomeFeed,
        );

      setHomeFeedSavedSearch(homeFeedSavedSearchItem);
    });
  }, [
    activeSearchText,
    searchTypes,
    searchIndustries,
    searchLandscapes,
    searchEntities,
    searchCountries,
    searchDates,
  ]);

  const totalResults = getTotalSearchResults({
    entities: entityResults,
    facets: facetResults,
    resultType: visibleResultType,
  });

  const isLoading = isLoadingResults({
    isLoadingFacets: isFacetResultsLoading,
    isLoadingEntities: isEntityResultsLoading,
    resultType: visibleResultType,
  });

  const [startDate, endDate] = searchDates;

  const showType = searchTypes.length > 0;
  const showIndustry = searchIndustries.length > 0;
  const showCountry = searchCountries.length > 0;
  const showEntities = searchEntities.length > 0;
  const showDates = startDate && endDate;

  const handleSaveSearchNameChange = (event) => {
    setSaveSearchName(event.target.value);
  };

  const handleOpenSaveSearchModal = (type) => {
    setUpdateType(type);
    setSaveSearchName(savedSearchName);
    setOpenSaveSearchModal(true);
  };

  const handleCloseSaveSearchModal = () => {
    setOpenSaveSearchModal(false);
  };

  const handleSaveSearch = async (payload) => {
    let result;
    if (savedSearchId) {
      result = await updateSavedSearchMutation({
        client,
        savedSearchId,
        updatedName: saveSearchName || savedSearchName,
        updatedData: JSON.stringify(payload),
      });
    } else {
      result = await createSavedSearchMutation({
        client,
        searchName: saveSearchName,
        searchData: JSON.stringify(payload),
      });
    }

    setOpenSaveSearchModal(false);
    if (result) {
      setSuccessfulSaveSearch(true);
      if (!payload.isHomeFeed) {
        setHomeFeedSavedSearch(null);
      }
      notify.success('Search has been saved!', { position: 'bottom-right' });
      client
        .query({
          query: listSavedSearchs,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) =>
          setSavedSearches(data.listSavedSearchs.savedSearches),
        );
    } else {
      notify.error('Something went wrong!', { position: 'bottom-right' });
    }
  };

  const goToSemanticSearch = () => {
    params.set('question', activeSearchText);
    params.delete('search');

    navigate(ROUTES.AI_SEARCH, params.toString());
  };

  if ((isLoading && totalResults === 0) || searchTypes.length === 0)
    return null;

  const andIn = (v) => (v ? ' and ' : ' in ');

  return (
    <div className={css.header}>
      <div className={css.filterStatus}>
        <Button
          onClick={toggleFilter}
          aria-pressed={isFilterOpen}
          data-status={isFilterOpen ? 'expanded' : 'closed'}
        >
          <FilterIcon />
          Filters
        </Button>

        <p data-cy="search-results-title" className={css.title}>
          {getResultsText(totalResults, activeSearchText)}

          {showType && (
            <>
              {andIn(false)}
              <SearchHeaderGroup
                results={searchTypes}
                summaryText={`${searchTypes.length} types`}
                formatResult={getLabelFromType}
              />
            </>
          )}

          {showCountry && (
            <>
              {andIn(showType)}
              <SearchHeaderGroup
                results={searchCountries}
                summaryText={`${searchCountries.length} countries`}
                formatResult={(country) => country.name}
              />
            </>
          )}

          {showIndustry && (
            <>
              {andIn(showCountry || showType)}
              <SearchHeaderGroup
                results={searchIndustries}
                summaryText={`${searchIndustries.length} filters`}
                formatResult={(industry) => industry.name}
              />
            </>
          )}

          {showEntities && (
            <>
              {' related to '}
              <SearchHeaderGroup
                results={searchEntities}
                summaryText={`${searchEntities.length} entities`}
                formatResult={(entity) => entity.name}
              />
            </>
          )}

          {showDates && (
            <>
              {' between '}
              <ResultHeaderHighlight text={formatDate(startDate)} />
              {' and '}
              <ResultHeaderHighlight text={formatDate(endDate)} />
            </>
          )}
        </p>
      </div>

      <div className={css.saveSearchContainer}>
        <button
          data-cy="save-search-button"
          type="button"
          className={css.searchButton}
          data-status={successfulSaveSearch ? 'saved' : 'default'}
          data-feed-action={
            successfulSaveSearch && homeFeedSavedSearch ? 'remove' : 'set'
          }
          onClick={() =>
            handleOpenSaveSearchModal(
              successfulSaveSearch && homeFeedSavedSearch
                ? 'removeHomeFeed'
                : 'setHomeFeed',
            )
          }
        >
          <HomeIcon
            className={css.homeIcon}
            color={successfulSaveSearch && homeFeedSavedSearch ? '#cc0909' : ''}
          />{' '}
          {successfulSaveSearch && homeFeedSavedSearch
            ? 'Remove as home feed'
            : 'Set as home feed'}
        </button>
        <button
          data-cy="save-search-button"
          type="button"
          className={css.searchButton}
          data-status={successfulSaveSearch ? 'saved' : 'default'}
          onClick={() => handleOpenSaveSearchModal('save')}
        >
          {successfulSaveSearch ? '✓ Saved Search' : 'Save Search'}
        </button>

        {activeSearchText && (
          <>
            Results don’t look right?
            <button
              type="button"
              data-type="secondary"
              className={css.searchButton}
              onClick={goToSemanticSearch}
            >
              Switch to AI search
            </button>
          </>
        )}
      </div>

      <SavedSearchModal
        activeSearch={activeSearchText}
        openSaveSearchModal={openSaveSearchModal}
        saveSearchName={saveSearchName}
        handleSaveSearchNameChange={handleSaveSearchNameChange}
        handleSaveSearch={handleSaveSearch}
        handleCloseSaveSearchModal={handleCloseSaveSearchModal}
        searchCountries={searchCountries}
        searchTypes={searchTypes}
        searchIndustries={searchIndustries}
        searchEntities={searchEntities}
        searchDates={searchDates}
        searchLandscapes={searchLandscapes}
        updateType={updateType}
      />
    </div>
  );
};

export default SearchHeader;
