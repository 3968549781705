/* eslint-disable no-shadow */
import React, { useMemo, useRef, useState } from 'react';

import { DateRangePicker } from 'react-date-range';
import { endOfDay, format, startOfDay } from 'date-fns';
import css from './DateFilter.module.css';
import FilterPopUp from '../components/FilterPopUp/FilterPopUp';
import { PopUpCard } from '../components/PopUpCard/PopUpCard';
import FilterButton from '../components/FilterButton/FilterButton';
import { getSearchQuickRanges } from '../../../../lib/search';
import SingleSelectionButton from '../components/SingleSelectionButton/SingleSelectionButton';
import { isNullOrUndefined } from '../../../../utils';

const parseDate = (date) => {
  if (!date) return null;
  return date instanceof Date ? date : new Date(date);
};

function DateFilter({
  dateInterval,
  setDateInterval,
  singleSelection = false,
  openAtBottom = false,
}) {
  const buttonRef = useRef();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [focusRange, setFocusRange] = useState([0, 0]);
  const [dateRange, setDateRange] = useState(dateInterval);

  const isStaticDate = (date) => {
    return new Date(date).getMilliseconds() !== 0;
  };

  const handleButtonClick = () => {
    // setIsPopupOpen(true);
    setIsPopupOpen((v) => !v);
  };

  const onClose = () => {
    setIsPopupOpen(false);
  };

  const onClear = () => {
    setDateRange([null, null]);
    // setDateInterval([null, null]);
    // onClose();
  };

  const onApply = () => {
    onClose();
    const [startDate, endDate] = dateRange;

    if (!startDate || !endDate) return;

    if (isStaticDate(startDate)) {
      setDateInterval([startDate, endDate]);
      return;
    }

    setDateInterval([startOfDay(startDate), endOfDay(endDate)]);
  };

  const handleDateSelect = (dateRangeInfo) => {
    // Weird behavior: Sometimes it's reported as { selection: <value> } and others as { range1: <value> }
    const key = Object.keys(dateRangeInfo)[0];
    const { [key]: dateRange } = dateRangeInfo;
    const { startDate, endDate } = dateRange;

    if (isStaticDate(startDate)) {
      setDateRange([startDate, endDate]);
      return;
    }

    setDateRange([startOfDay(startDate), endOfDay(endDate)]);
  };

  const handleFocusChange = (rangeItem) => {
    setFocusRange(rangeItem);
  };

  const staticRanges = useMemo(() => {
    return getSearchQuickRanges();
  }, []);

  const dates = {
    startDate: parseDate(dateRange[0]),
    endDate: parseDate(dateRange[1]),
  };

  const [start, end] = useMemo(() => {
    if (isNullOrUndefined(dateInterval[0])) return '';

    const startDate = parseDate(dateInterval[0]);
    const endDate = parseDate(dateInterval[1]);
    return [format(startDate, 'MMMM d'), format(endDate, 'MMMM d')];
  }, [dates]);

  const [selectedStart, selectedEnd] = useMemo(() => {
    if (dates.startDate === null) return '';

    return [format(dates.startDate, 'MMMM d'), format(dates.endDate, 'MMMM d')];
  }, [dates]);

  const count = start ? `${start} - ${end}` : '';

  return (
    <div data-cy="date-filter" ref={buttonRef}>
      <FilterPopUp
        isPopoverOpen={isPopupOpen}
        onClickOutside={onClose}
        openAtBottom={openAtBottom}
        content={
          <PopUpCard.Root>
            <PopUpCard.DatePickerHeader
              startDate={selectedStart}
              endDate={selectedEnd}
            />
            <PopUpCard.Body customClass={css.customBody}>
              <DateRangePicker
                className={css.dateRange}
                startDatePlaceholder="Start date"
                endDatePlaceholder="End date"
                onChange={handleDateSelect}
                focusedRange={focusRange}
                onRangeFocusChange={handleFocusChange}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[dates]}
                direction="vertical"
                rangeColors={['#f3f3f3']}
                weekStartsOn={1}
                staticRanges={staticRanges}
                inputRanges={[]}
                maxDate={new Date()}
                preventSnapRefocus
              />
            </PopUpCard.Body>
            <PopUpCard.DateFooter
              customClass={css.customFooter}
              onCancel={onClose}
              onApply={onApply}
              onClear={onClear}
            />
          </PopUpCard.Root>
        }
      >
        {singleSelection ? (
          <SingleSelectionButton
            aria-pressed={isPopupOpen}
            name={count || 'Select Date'}
            onClick={handleButtonClick}
          />
        ) : (
          <FilterButton
            aria-pressed={isPopupOpen}
            name="Date"
            count={count}
            onClick={handleButtonClick}
          />
        )}
      </FilterPopUp>
    </div>
  );
}

export default DateFilter;
